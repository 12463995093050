import { default as Component } from 'vue-class-component';
import WithRender from './elli.html';
import { HeaderV2 } from '@/components/template/header-v2';

@WithRender
@Component({})
export class ElliHeaderV2 extends HeaderV2 {
    public get isElli() {
        return [
            'elli',
            'elli-thg-audi',
            'elli-thg-elli',
            'elli-thg-man',
            'elli-thg-cupra',
            'elli-thg-dealer',
            'elli-thg-skoda',
            'elli-thg-vw',
            'elli-thg-vwfs',
            'elli-thg-freenet',
        ].includes(process.env.VUE_APP_ENVIRONMENT ?? '');
    }
    protected get showLogoLine() {
        return [
            'elli',
            'elli-thg-audi',
            'elli-thg-elli',
            'elli-thg-man',
            'elli-thg-cupra',
            'elli-thg-dealer',
            'elli-thg-skoda',
            'elli-thg-vw',
            'elli-thg-vwfs',
            'elli-thg-freenet',
        ].includes(process.env.VUE_APP_ENVIRONMENT ?? '');
    }
    protected get showLogoTop() {
        return ['elli', 'elli-thg-dealer'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineAudi() {
        return ['elli-thg-audi'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineCupra() {
        return ['elli-thg-cupra'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineSkoda() {
        return ['elli-thg-skoda'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineVolkswagen() {
        return ['elli', 'elli-thg-vw', 'elli-thg-dealer'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineVolkswagenFS() {
        return ['elli-thg-vwfs'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }
    protected get showLogoLineFreenet() {
        return ['elli-thg-freenet'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }

    protected get showLogoLineElli() {
        return ['elli-thg-elli'].includes(
            process.env.VUE_APP_ENVIRONMENT ?? ''
        );
    }

    protected get showLogoLineMan() {
        return ['elli-thg-man'].includes(process.env.VUE_APP_ENVIRONMENT ?? '');
    }
}
