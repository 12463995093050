import iocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Contracts from '@/interfaces/Contracts';
import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './call-to-action-banner-widget.html';
import * as Sentry from '@sentry/browser';
import { Watch } from 'vue-property-decorator';

const contractProvider = iocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({})
export class CallToActionBannerWidget extends Base {
    protected isDisplayed = false;
    protected text = '';
    protected url = '';
    protected buttonText = '';

    protected prefix = null;

    protected elements = {
        // bsw: ['show-logo'],
    };

    protected created(): void {
        this.loadData();
    }

    get contractId() {
        return this.$store.state.contracts.contractId;
    }

    get faIcon() {
        if (this.prefix) {
            return this.$store.state.settings[
                'callToActionFaIcon' + this.prefix
            ];
        }
        return this.$store.state.settings.callToActionFaIcon;
    }

    protected loadData(): void {
        if (!this.contractId) return;
        contractProvider
            .callToActionBanner(this.contractId)
            .then((response) => {
                if (response.data.display) {
                    this.isDisplayed = true;
                    if (
                        this.$store.state.settings
                            .callToActionBannerPriceCondition
                    ) {
                        if (
                            this.currentTariff.tariff &&
                            this.currentTariff.tariff.workingPrices &&
                            this.currentTariff.tariff.workingPrices[0]
                        ) {
                            this.checkShowByPrice();
                        } else {
                            this.isDisplayed = false;
                        }
                    }
                    this.text = response.data.text || '';
                    this.url = response.data.url || '';
                    this.buttonText = response.data.buttonText || '';
                    this.prefix = response.data.prefix || null;
                }
            })
            .catch((error) => {
                Sentry.captureException(new Error(error));
            });
    }

    @Watch('currentTariff.tariff.workingPrices')
    protected onCurrentTariffChanged() {
        this.checkShowByPrice();
    }

    protected checkShowByPrice() {
        if (
            this.$store.state.settings.callToActionBannerPriceCondition &&
            this.currentTariff.tariff &&
            this.currentTariff.tariff.workingPrices &&
            this.currentTariff.tariff.workingPrices[0]
        ) {
            let priceCondition: string[];
            if (
                this.$store.state.settings.callToActionBannerPriceCondition.includes(
                    '>'
                ) ||
                this.$store.state.settings.callToActionBannerPriceCondition.includes(
                    '<'
                )
            ) {
                priceCondition =
                    this.$store.state.settings.callToActionBannerPriceCondition.split(
                        ' '
                    );
            } else {
                priceCondition = [
                    '>',
                    this.$store.state.settings.callToActionBannerPriceCondition,
                ];
            }
            const price =
                this.currentTariff.tariff.workingPrices[0].workingPriceBrutto;
            if (priceCondition[0] === '>') {
                this.isDisplayed = price > parseFloat(priceCondition[1]);
            } else if (priceCondition[0] === '<') {
                this.isDisplayed = price < parseFloat(priceCondition[1]);
            }
        }
    }

    protected openUrl() {
        if (this.url && this.url.length > 0) {
            window.open(this.url, '_blank');
        }
    }
    get logoUrl() {
        const path = '/img/evus/' + this.currentClient;
        switch (this.currentClient) {
            case 'bsw':
                return path + '/DKB_Crowd_DKB_RGB_weiss.svg';
            default:
                return '';
        }
    }
}
