import { injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import Customer from '../interfaces/Customer';
import SubmitCustomer from '../interfaces/SubmitCustomer';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class CustomerProvider
    extends PrototypeProvider
    implements Customer
{
    public async customerDetails(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/customer', this.config);
    }

    public async communications(
        limit: number,
        offset: number
    ): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get(
            '/customer/communications/' + limit + '/' + offset,
            this.config
        );
    }

    public async update(data: SubmitCustomer): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/customer', data, this.config);
    }

    public async updateAdsBonus(contractId: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/customer/ads/bonus',
            { contractId },
            this.config
        );
    }

    public async updateAds(contractId: number): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post(
            '/customer/ads',
            { contractId },
            this.config
        );
    }

    public async checkOptOut(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/customer/checkOptOut', this.config);
    }

    public async adsBonusAvailable(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/customer/ads/bonus', this.config);
    }

    public async declineAdsSending(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.post('/customer/declineAds', {}, this.config);
    }

    public async getFleetManagerQuotas(): Promise<AxiosResponse> {
        this.createConfig();
        return await this.http.get('/customer/fleetManagerQuotas', this.config);
    }
}
