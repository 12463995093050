<form-wrapper form="formCustomerData">
    <div
        class="mobile-modal__inner"
        id="change-customer-data-widget"
        data-test="change-customer-data-widget"
    >
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-customer-data-widget-close-click"
                    v-if="!displayElement('hide-close-button')"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close change-customer-details-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <i
                            class="far fa-user readingHistory-icon mt-2"
                            v-if="displayElement('user-icon')"
                        ></i>
                        <i
                            class="fa fa-user-edit readingHistory-icon mt-2"
                            v-if="displayElement('user-edit-icon')"
                        ></i>
                        <i
                            class="fa fa-edit readingHistory-icon mt-2"
                            v-if="displayElement('edit-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.ccd.title') }}</span
                        >
                        <span class="ccd-box-subtitle" v-if="showSubtitle"
                            >{{ $t("widget.ccd.subtitle") }}</span
                        >
                        <div
                            class="actionMiddleWidget"
                            @click="changeShowContent"
                            v-if="displayElement('show-more-button')"
                            data-test="change-widget-customer-data-click"
                        >
                            <i class="fa fa-ellipsis-h"></i>
                        </div>

                        <div
                            class="notice"
                            v-if="displayElement('show-header-notice')"
                        >
                            Hinweis: Grauhinterlegte Felder können nicht direkt
                            über das Kundenportal geändert werden. Falls Sie die
                            Daten ändern möchten, kontaktieren Sie uns bitte per
                            Kontaktformular.
                        </div>

                        <div
                            id="customerDataChange-content"
                            class="mobile-modal__wrap"
                            v-if="showContent"
                        >
                            <div id="customerDataChangeForm">
                                <div
                                    v-if="displayElement('disableDataChange')"
                                    class="alert alert-warning"
                                >
                                    {{
                                    $t('widget.ccd.disableDataChange.warning.1')
                                    }}<br />
                                    {{
                                    $t('widget.ccd.disableDataChange.warning.2')
                                    }}
                                </div>
                                <div class="row">
                                    <div class="col form-col">
                                        <form-group
                                            id="change-customer-data-widget__company"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.company')
                                                }}<sup
                                                    v-if="displayElement('customer_edit_fields-footnote')"
                                                    >*</sup
                                                ></custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.company.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.company.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.company')"
                                                v-model="details.company"
                                            />
                                        </form-group>
                                        <div class="row">
                                            <form-group
                                                :class="[
                                                    displayElement('col-6-field') ? 'col-6' : 'col-12',
                                                    displayElement('customer_salutation-disabled') ? 'disabled' : ''
                                                    ]"
                                                id="change-customer-data-widget__salutation"
                                            >
                                                <custom-label
                                                    >{{
                                                    $t('widget.ccd.salutation')}}<sup
                                                        v-if="displayElement('customer_edit_fields-footnote')"
                                                        >*</sup
                                                    ></custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.salutation.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.salutation.tooltip')"
                                                ></i>
                                                <custom-v-select
                                                    :options="salutationOptions"
                                                    label="label"
                                                    :value="salutationCurrentlySelected"
                                                    @input="(object) => (object) ? details.salutation = object.value : details.salutation = null"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                                <span></span>
                                            </form-group>
                                            <form-group
                                                class="form-group col-12"
                                                id="change-customer-data-widget__postbox"
                                                v-if="isShowPostboxField"
                                            >
                                                <div>
                                                    <custom-checkbox
                                                        :options="checkboxOptions()"
                                                        v-model="showPostboxField"
                                                        inputClass="form-check-input"
                                                        :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                        :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                        ]"
                                                    />
                                                </div>
                                                <div v-if="showPostboxField">
                                                    <br />
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.postbox')
                                                        }}</custom-label
                                                    >
                                                    <custom-input
                                                        type="text"
                                                        :class="['form-control', isPostboxError ? 'form-postbox-error' : '']"
                                                        :placeholder="$t('widget.ccd.postbox')"
                                                        v-model="details.postbox"
                                                    />
                                                </div>
                                            </form-group>

                                            <form-group
                                                :class="[
                                                        displayElement('col-6-field') ? 'col-6' : 'col-12',
                                                        ]"
                                                id="change-customer-data-widget__title"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.titel')
                                                    }}<sup
                                                        v-if="displayElement('customer_edit_fields-footnote')"
                                                        >*</sup
                                                    ></custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.titel.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.titel.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.titel')"
                                                    v-model="details.title"
                                                />
                                            </form-group>
                                        </div>

                                        <form-group
                                            id="change-customer-data-widget__firstname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.firstname')
                                                }}<sup
                                                    v-if="displayElement('customer_edit_fields-footnote')"
                                                    >*</sup
                                                ></custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.firstname.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.firstname.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.firstname')"
                                                v-model="details.firstname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__lastname"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.lastname')
                                                }}<sup
                                                    v-if="displayElement('customer_edit_fields-footnote')"
                                                    >*</sup
                                                ></custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.lastname.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.lastname.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.lastname')"
                                                v-model="details.surname"
                                            />
                                        </form-group>

                                        <template>
                                            <form-group
                                                id="change-customer-data-widget__zip"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.zip')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.zip.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.zip.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.zip')"
                                                    v-model="details.zip"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.place')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.place.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.place.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.place')"
                                                    v-model="details.city"
                                                />
                                            </form-group>
                                        </template>
                                    </div>

                                    <div class="col form-col">
                                        <template v-if="showSelectAddress">
                                            <form-group
                                                id="change-customer-data-widget__zip-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{
                                                    $t('widget.mo.post.code')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'zip')"
                                                    @input="cityInput"
                                                    label="zipText"
                                                    v-model="selectedCityZip"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__city-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                    >{{ $t('widget.mo.place')
                                                    }}</custom-label
                                                >
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'name')"
                                                    @input="cityInput"
                                                    label="nameText"
                                                    v-model="selectedCityName"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__street-select"
                                                v-if="selectedCityName"
                                            >
                                                <custom-label
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.street') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="streets"
                                                    v-model="selectedStreet"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                    @input="streetInput"
                                                    label="name"
                                                >
                                                    <template #no-options>
                                                        {{
                                                        $t('widget.mo.no.city')
                                                        }}
                                                    </template>
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                v-if="selectedCityName"
                                                id="change-customer-data-widget__house-number"
                                            >
                                                <custom-label
                                                    :class="[houseNumberError ? 'text-danger' : '']"
                                                    >{{
                                                    $t('widget.mo.house.number')
                                                    }}</custom-label
                                                >
                                                <custom-input
                                                    type="text"
                                                    :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                                    v-model="details.houseNumber"
                                                    @change="houseNumberError = false"
                                                />
                                            </form-group>
                                        </template>
                                        <template v-else>
                                            <form-group
                                                id="change-customer-data-widget__street"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.street')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.street.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.street.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.street')"
                                                    v-model="details.street"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-customer-data-widget__house-number"
                                            >
                                                <custom-label
                                                    >{{ $t('widget.ccd.house')
                                                    }}</custom-label
                                                >
                                                <i
                                                    class="fas fa-info-circle"
                                                    v-if="$t('widget.ccd.house.tooltip')"
                                                    v-tooltip.top="$t('widget.ccd.house.tooltip')"
                                                ></i>
                                                <custom-input
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.ccd.house')"
                                                    v-model="details.houseNumber"
                                                />
                                            </form-group>
                                        </template>
                                        <form-group
                                            id="change-customer-data-widget__date-of-birth"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.date') }}<sup
                                                    v-if="displayElement('customer_edit_fields-footnote')"
                                                    >*</sup
                                                >
                                            </custom-label>
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.date.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.date.tooltip')"
                                            ></i>
                                            <custom-input
                                                v-if="displayElement('date-input')"
                                                v-model="details.dateOfBirth"
                                                type="date"
                                                class="form-control"
                                            />
                                            <custom-datetime
                                                v-else
                                                v-model="details.dateOfBirth"
                                                type="date"
                                                input-class="form-control"
                                                value-zone="local"
                                                format="dd.LL.yyyy"
                                            ></custom-datetime>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-mobile"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.phone')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.phone.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.phone.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-12">
                                                    <custom-input
                                                        data-test="change-customer-data-widget-input--phone-number"
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.cdc.phone.full.number')"
                                                        v-model="details.phoneMobile"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-mobile-with-areacode"
                                        >
                                            <custom-label
                                                >{{ $t('widget.ccd.phone')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.phone.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.phone.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-input
                                                        data-test="change-customer-data-widget-input--prephone-number"
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneMobileAreaCode"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-input
                                                        data-test="change-customer-data-widget-input--phone-number"
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneMobile"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-home"
                                        >
                                            <custom-label
                                                v-if="displayElement('customer-phone-label') && !details.company"
                                                >{{ $t('widget.ccd.fixedPhone')
                                                }}</custom-label
                                            >
                                            <custom-label v-else
                                                >{{
                                                $t('widget.ccd.fixedPrivate')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-12">
                                                    <custom-input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.cdc.phone.full.number')"
                                                        v-model="details.phoneHome"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-home-with-areacode"
                                        >
                                            <custom-label
                                                v-if="displayElement('customer-phone-label') && !details.company"
                                                >{{ $t('widget.ccd.fixedPhone')
                                                }}</custom-label
                                            >
                                            <custom-label v-else
                                                >{{
                                                $t('widget.ccd.fixedPrivate')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneHomeAreaCode"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneHome"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-business"
                                            v-if="details.company"
                                        >
                                            <custom-label>
                                                {{
                                                $t('widget.cdc.additional.phone')
                                                }}
                                            </custom-label>
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-12">
                                                    <custom-input
                                                        type="number"
                                                        class="form-control"
                                                        :placeholder="$t('widget.cdc.phone.full.number')"
                                                        v-model="details.phoneBusiness"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__phone-business-with-areacode"
                                            v-if="details.company"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.fixedBusiness')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.fixedBusiness.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.fixedBusiness.tooltip')"
                                            ></i>
                                            <div class="row">
                                                <div class="col-5">
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.area')"
                                                        v-model="details.phoneBusinessAreaCode"
                                                        :disabled="displayElement('disableDataChange')"
                                                    />
                                                </div>
                                                <div class="col-7">
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.phone.number')"
                                                        v-model="details.phoneBusiness"
                                                        :disabled="displayElement('disableDataChange')"
                                                    />
                                                </div>
                                            </div>
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__email-private"
                                        >
                                            <custom-label>
                                                <template
                                                    v-if="displayElement('customer-email-label') && !details.company"
                                                >
                                                    {{ $t('widget.ccd.email')
                                                    }}{{displayElement('email*')
                                                    ? '*' : ''}}
                                                </template>
                                                <template v-else>
                                                    {{
                                                    $t('widget.ccd.emailPrivate')
                                                    }}{{displayElement('email*')
                                                    ? '*' : ''}}
                                                </template>
                                            </custom-label>
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.emailPrivate.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.emailPrivate.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="email"
                                                :class="['form-control', isRequiredField.emailPrivate && displayElement('customer-required-field') ? 'is-invalid' : '']"
                                                :placeholder="$t('widget.ccd.emailPrivate')"
                                                v-model="details.emailPrivate"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__email-business"
                                        >
                                            <custom-label
                                                >{{
                                                $t('widget.ccd.emailBusiness')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.emailBusiness.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.emailBusiness.tooltip')"
                                            ></i>
                                            <custom-input
                                                type="email"
                                                class="form-control"
                                                :placeholder="$t('widget.ccd.emailBusiness')"
                                                v-model="details.emailBusiness"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__communication-type"
                                        >
                                            <custom-label
                                                :class="[customerCommunicationTypeError ? 'text-danger' : '']"
                                                >{{
                                                $t('widget.ccd.customerCommunicationPrefered')
                                                }}</custom-label
                                            >
                                            <i
                                                class="fas fa-info-circle"
                                                v-if="$t('widget.ccd.customerCommunicationPrefered.tooltip')"
                                                v-tooltip.top="$t('widget.ccd.customerCommunicationPrefered.tooltip')"
                                            ></i>

                                            <custom-v-select
                                                v-if="!displayElement('customerCommunicationType-radioButton')"
                                                :options="communicationType"
                                                @input="communicationTypeInput"
                                                label="label"
                                                :value="communicationTypeCurrentlySelected"
                                                @change="customerCommunicationTypeError = false"
                                                :class="[customerCommunicationTypeError ? 'is-invalid' : '']"
                                            >
                                            </custom-v-select>
                                            <div
                                                v-else
                                                class="form-group-radio-button"
                                            >
                                                <custom-radio
                                                    v-model="communicationTypeSelectedId"
                                                    :options="communicationTypeRadioOptions()"
                                                ></custom-radio>
                                            </div>
                                        </form-group>
                                        <div
                                            v-if="displayElement('customer_ads_mail') && displayAddLabel"
                                        >
                                            <label
                                                >{{ $t('widget.ccd.ads.choose')
                                                }}</label
                                            >
                                        </div>
                                        <form-group
                                            id="change-customer-data-widget__ads-mail"
                                        >
                                            <custom-checkbox
                                                inputClass="form-check-input"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.adsMail"
                                                :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                        ]"
                                                :options="[
                                                                {
                                                                    value: false,
                                                                    label: `${this.$t('widget.ccd.ads.mail').toLocaleString()}`,
                                                                },
                                                            ]"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__ads-phone"
                                        >
                                            <custom-checkbox
                                                inputClass="form-check-input"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.adsPhone"
                                                :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                        ]"
                                                :options="[
                                                                {
                                                                    value: false,
                                                                    label: `${this.$t('widget.ccd.ads.phone').toLocaleString()}`,
                                                                },
                                                            ]"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__ads-post"
                                        >
                                            <custom-checkbox
                                                inputClass="form-check-input"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.adsPost"
                                                :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                        ]"
                                                :options="[
                                                                {
                                                                    value: false,
                                                                    label: `${this.$t('widget.ccd.ads.post').toLocaleString()}`,
                                                                },
                                                            ]"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-customer-data-widget__ads-confirmation"
                                            v-if="showAdsConfirmation"
                                        >
                                            <custom-checkbox
                                                inputClass="form-check-input"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.adsConfirmation"
                                                :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                        ]"
                                                :options="[
                                                                {
                                                                    value: false,
                                                                    label: `${this.$t('widget.ccd.ads.confirmation').toLocaleString()}`,
                                                                },
                                                            ]"
                                            />
                                        </form-group>
                                    </div>
                                    <form-group
                                        id="change-customer-data-widget__synchronize-with-billing--excluded"
                                        v-if="showSyncBilling"
                                    >
                                        <custom-checkbox
                                            type="checkbox"
                                            :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                            inputClass="form-check-input"
                                            v-model="details.synchronizeWithBilling"
                                            :options="[
                                                {value: true, label: `${this.$t('widget.ccd.acceptAsBilling').toLocaleString()}`,}
                                                ]"
                                        />
                                    </form-group>
                                </div>
                                <div class="row">
                                    <form-group
                                        v-if="displayDataConfirmationCheckBox"
                                        id="change-customer-data-widget__data-valid--excluded"
                                    >
                                        <custom-checkbox
                                            :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                            :labelClass="[
                                        'form-check-label',
                                        displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '',
                                        dataValidError ? 'is-invalid' : ''
                                    ]"
                                            v-model="dataValid"
                                            @change="dataValidError = false"
                                            inputClass="form-check-input"
                                            :options="dataValidCheckboxOptions()"
                                        />
                                    </form-group>
                                    <form-group
                                        id="change-customer-data-widget__all-ads-accepted"
                                    >
                                        <custom-checkbox
                                            inputClass="form-check-input"
                                            :options="allAdsCheckboxOptions()"
                                            :wrapperClass="[displayElement('branded-checkbox') ? 'custom-check form-check-label' : '']"
                                            v-model="allAdsAccepted"
                                            :labelClass="[
                                                'form-check-label', 'm-0', 'd-inline',
                                                displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                            ]"
                                        />
                                    </form-group>
                                </div>
                                <div class="row ccd-form-row-2">
                                    <div class="col-sm-8 col-xs-12">
                                        <template
                                            v-if="displayElement('customer_edit_fields-footnote')"
                                            >{{
                                            $t('widget.ccd.footnote.edit.fields')
                                            }}</template
                                        >

                                        <messages-block
                                            fromTestElement="change-customer-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div
                                        v-if="!displayElement('disableDataChange')"
                                        class="col-sm-4 col-xs-12"
                                    >
                                        <div class="text-end">
                                            <button
                                                data-test="change-customer-data-widget-save-click"
                                                class="btn btn-primary"
                                                @click="submit"
                                                :disabled="submitButtonDisabled"
                                            >
                                                {{ $t('widget.ccd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
