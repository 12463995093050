<form-wrapper form="formBillingData">
    <div class="mobile-modal__inner" data-test="change-billing-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-billing-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <i
                            class="fa fa-user-edit readingHistory-icon mt-2"
                            v-if="displayElement('edit-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.cbd.title') }}</span
                        >
                        <div
                            id="billingDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div id="billingDataChangeForm">
                                <div class="row">
                                    <div class="col form-col">
                                        <form-group
                                            id="change-billing-data-widget__company"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.company') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.company')"
                                                v-model="details.billingCompany"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__salutation"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.salutation')
                                                }}
                                            </custom-label>
                                            <custom-v-select
                                                label="label"
                                                :options="salutationOptions"
                                                v-model="selectedSalutation"
                                                @input="salutationInput"
                                            >
                                            </custom-v-select>
                                            <span></span>
                                        </form-group>
                                        <form-group
                                            v-if="isPostboxEnabled"
                                            id="change-billing-data-widget__postbox"
                                        >
                                            <custom-checkbox
                                                inputClass="form-check-input"
                                                v-model="showPostboxField"
                                                :options="checkboxOptionsPostbox()"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                :labelClass="[
                                                    'form-check-label',
                                                    displayElement('branded-checkbox')
                                                        ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                        : ''
                                                ]"
                                            />
                                        </form-group>
                                        <form-group v-if="showPostboxField">
                                            <custom-label>
                                                {{ $t('widget.cbd.postbox') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                :class="['form-control', isPostboxError ? 'form-postbox-error' : '']"
                                                :placeholder="$t('widget.ccd.postbox')"
                                                v-model="details.postbox"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__firstname"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.firstName') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.firstName')"
                                                v-model="details.billingFirstName"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__surname"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.lastName') }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.lastName')"
                                                v-model="details.billingSurname"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__care-of-recipient"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.co') }}
                                            </custom-label>
                                            <custom-input
                                                id="billing_co"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.co')"
                                                v-model="details.billingCareOfRecipient"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__phone-mobile"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.mobilePhone')
                                                }}
                                            </custom-label>
                                            <custom-input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.mobilePhone')"
                                                v-model="details.billingPhoneMobile"
                                            />
                                        </form-group>
                                    </div>
                                    <div class="col form-col">
                                        <template
                                            v-if="showSelectBillingAddress"
                                        >
                                            <form-group
                                                id="change-billing-data-widget__zip-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.post.code')
                                                    }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'zip')"
                                                    @input="cityInput"
                                                    label="zip"
                                                    v-model="selectedCityZip"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                v-if="selectedCityName"
                                                id="change-billing-data-widget__city-select"
                                            >
                                                <custom-label
                                                    :class="[cityError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.place') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="cities"
                                                    @search="citySearch($event, 'name')"
                                                    @input="cityInput"
                                                    label="nameText"
                                                    v-model="selectedCityName"
                                                    :class="[cityError ? 'is-invalid' : '']"
                                                >
                                                </custom-v-select>
                                            </form-group>
                                            <form-group
                                                v-if="selectedCityName"
                                                id="change-billing-data-widget__street-select"
                                            >
                                                <custom-label
                                                    :class="[streetError ? 'text-danger' : '']"
                                                >
                                                    {{ $t('widget.mo.street') }}
                                                </custom-label>
                                                <custom-v-select
                                                    :options="streets"
                                                    @input="streetInput"
                                                    label="nameText"
                                                    v-model="selectedStreet"
                                                    :class="[streetError ? 'is-invalid' : '']"
                                                >
                                                </custom-v-select>
                                            </form-group>
                                        </template>
                                        <template>
                                            <form-group
                                                id="change-billing-data-widget__zip"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.zip') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_zip"
                                                    data-test="change-billing-data-widget-input--zip"
                                                    type="text"
                                                    class="form-control"
                                                    :class="[zipFailed ? 'validation-failed': '']"
                                                    :placeholder="$t('widget.cbd.zip')"
                                                    v-model="details.billingZip"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-billing-data-widget__city"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.place') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_city"
                                                    data-test="change-billing-data-widget-input--city"
                                                    type="text"
                                                    class="form-control"
                                                    :class="[zipFailed ? 'validation-failed': '']"
                                                    :placeholder="$t('widget.cbd.place')"
                                                    v-model="details.billingCity"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-billing-data-widget__street"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.street')
                                                    }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_street"
                                                    data-test="change-billing-data-widget-input--street"
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cbd.street')"
                                                    v-model="details.billingStreet"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-billing-data-widget__house-number"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.house') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_houseNumber"
                                                    data-test="change-billing-data-widget-input--house-number"
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cbd.house')"
                                                    v-model="details.billingHouseNumber"
                                                />
                                            </form-group>
                                        </template>
                                        <form-group
                                            id="change-billing-data-widget__email"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.email')
                                                }}{{displayElement('email*') ?
                                                '*' :''}}
                                            </custom-label>
                                            <custom-input
                                                id="billing_email"
                                                data-test="change-billing-data-widget-input--email"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.email')"
                                                v-model="details.billingEmail"
                                            />
                                        </form-group>
                                        <form-group
                                            id="change-billing-data-widget__overwrite-customer"
                                            class="form-group col-12"
                                        >
                                            <custom-checkbox
                                                :options="checkboxOptionsAcceptAsCustomer()"
                                                inputClass="form-check-input"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                v-model="details.synchronizeWithCustomer"
                                                :labelClass="[
                                                    'form-check-label',
                                                    displayElement('branded-checkbox')
                                                        ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                        : ''
                                                ]"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <a
                                    v-if="displayElement('show-personal-info-link')"
                                    :href="$t('widget.infoPersonalDataLink')"
                                    class="personal-info-link info-link-m0"
                                    target="_blank"
                                >
                                    {{ $t('widget.infoPersonalData') }}
                                </a>
                                <div class="row">
                                    <div class="col-sm-8 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-billing-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div
                                        v-if="!displayElement('disableDataChange')"
                                        class="col-sm-4 col-xs-12"
                                    >
                                        <div class="text-end">
                                            <button
                                                data-test="change-billing-data-widget-save-click"
                                                class="
                                                    btn btn-primary
                                                    float-right
                                                "
                                                @click="submit"
                                                :disabled="submitButtonDisabled"
                                            >
                                                {{ $t('widget.cbd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
